import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllDinnerParties } from '../../services/dp.service';

const initialState = {
  DPs: [],
};

export const getDinnerPartiesThunk = createAsyncThunk(
  'dinnerParties/getDinnerParties',
  async () => {
    const data = await getAllDinnerParties();
    return data;
  }
);

const DinnerPartiesSlice = createSlice({
  name: 'dinnerParties',
  initialState,
  extraReducers: {
    [getDinnerPartiesThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getDinnerPartiesThunk.fulfilled]: (state, action) => {
      state.DPs = action.payload;
      state.isLoading = false;
    },
    [getDinnerPartiesThunk.rejected]: (state) => {
      state.isLoading = false;
    },
  },
  reducers: {
    setDPs(state, { payload }) {
      state.DPs = payload;
    },
  },
});
export const { setDPs } = DinnerPartiesSlice.actions;
export default DinnerPartiesSlice.reducer;
