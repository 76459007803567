import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllRooms, createOneToOneRoom } from '../../services/chats.service';

const initialState = {
  chats: [],
};

export const getAllRoomsThunk = createAsyncThunk(
  'chat/getAllRooms',
  async (pageChat) => {
    const response = await getAllRooms(pageChat);
    return response;
  }
);

export const createOneToOneRoomThunk = createAsyncThunk(
  'chat/createOneToOneRoom',
  async (otherUserId) => {
    const data = await createOneToOneRoom(otherUserId);
    return data.data.data;
  }
);

export const Chats = createSlice({
  name: 'chats',
  initialState,
  extraReducers: {
    [getAllRoomsThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllRoomsThunk.fulfilled]: (state, action) => {
      state.chats = action.payload;
      state.isLoading = false;
    },
    [getAllRoomsThunk.rejected]: (state) => {
      state.isLoading = false;
    },
    [createOneToOneRoomThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [createOneToOneRoomThunk.fulfilled]: (state, action) => {
      state.chats = [...state.chats, action.payload];
      state.isLoading = false;
    },
    [createOneToOneRoomThunk.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default Chats.reducer;
