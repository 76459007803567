import axios from 'axios';
import { clearToken } from '../redux/slices/user';
import { store } from '../redux/store';

const http = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_STAGING_API}`,
});

http.interceptors.request.use(
  (config) => {
    const key = localStorage.getItem('access_token');
    if (key) {
      config.headers = {
        Authorization: `Bearer ${key}`,
      };
    }
    if (config.url.includes(':churchID')) {
      const newChurchId = localStorage.getItem('newChurchId');
      const position = config.url.indexOf(':churchID');
      const newUrl = config.url.split('');
      newUrl.splice(position, 9, newChurchId);
      config.url = newUrl.join('');
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      localStorage.removeItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      const newChurchId = localStorage.getItem('newChurchId');

      return await axios
        .post(`${process.env.NEXT_PUBLIC_STAGING_API}/auth/token/refresh/`, {
          refresh: refreshToken,
          church_id: newChurchId,
        })
        .then((response) => {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + response.data.access;
          localStorage.setItem('access_token', response.data.access);
          originalRequest._retry = true;
          return http(originalRequest);
        })
        .catch((err) => {
          originalRequest._retry = true;
          store.dispatch(clearToken());
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('access_token');
          localStorage.removeItem('expires_in');
          return http(originalRequest);
        });
    }

    return Promise.reject(error);
  }
);

const get = (url, headers = {}, params = {}) => {
  console.log(params)
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url, data, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url, data, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url, data, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};
const patch = (url, data, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

export default {
  http,
  get,
  post,
  put,
  remove,
  patch,
};
