import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const sideBarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openSidebar(state) {
      state.isOpen = true;
    },
    closeSidebar(state) {
      state.isOpen = false;
    },
  },
});

export const { closeSidebar, openSidebar } = sideBarSlice.actions;

export default sideBarSlice.reducer;
