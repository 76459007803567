import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChurchSchedules } from '../../services/schedule.service';

const initialState = {
  masterSchedules: [],
};

export const getMasterSchedulesThunk = createAsyncThunk(
  'schedules/getMasterSchedules',
  async () => {
    const newChurchId = localStorage.getItem('newChurchId');
    const data = await getChurchSchedules(newChurchId);
    return data;
  }
);

const SchedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  extraReducers: {
    [getMasterSchedulesThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getMasterSchedulesThunk.fulfilled]: (state, action) => {
      state.masterSchedules = action.payload.data.data;
      state.isLoading = false;
    },
    [getMasterSchedulesThunk.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default SchedulesSlice.reducer;
