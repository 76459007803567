import { combineReducers } from 'redux';

import sideBar from './slices/sidebar';
import user from './slices/user';
import members from './slices/members';
import dinnerParties from './slices/dinnerParties';
import chats from './slices/chats';
import church from './slices/church';
import searchInput from './slices/search';
import headers from './slices/header';
import schedules from './slices/schedule';

const reducers = combineReducers({
  headers,
  searchInput,
  sideBar,
  user,
  members,
  dinnerParties,
  chats,
  church,
  schedules,
});

export default reducers;
