import httpService from './http.service';

export const getDPDetails = async (dpId) => {
  const response = await httpService.get(
    `/dinner/${dpId}/details-with-attendance/`
  );
  return response.data;
};

export const getAttendanceOfDP = async (dpId, date) => {
  const response = await httpService.get(
    `/dinner/${dpId}/get-attendance/?date=${date}`
  );
  return response.data;
};

export const getAllDinnerParties = (type, page) =>
  httpService.get(
    `/dinner/v1/:churchID/get-all-dinner-parties/${
      type ? `?type=${type}` : ''
    }&cursor=${page || ''}`
  );

export const getDinnerPartiesByArchived = async (
  type,
  archived,
  title = ''
) => {
  const response = await httpService.get(
    `/dinner/v1/:churchID/get-all-dinner-parties/?${
      type ? 'type=' + type : ''
    }${archived ? '&is_archived=' + archived : ''}${
      title ? '&title=' + title : ''
    }`
  );
  return response.data;
};

export const getDinnerPartiesByPagination = async (
  churchID,
  type,
  page = '',
  is_archived,
  title = ''
) => {
  const response = await httpService.get(
    `/dinner/v1/${churchID}/get-all-dinner-parties/?type=${type}&cursor=${page}&${is_archived}${
      title ? '&title=' + title : ''
    }`
  );
  return response.data;
};

export const getGroupsList = (type) =>
  httpService.get(`/dinner/v1/:churchID/get-all-dinner-parties/?type=${type}`);

export const requestToJoinDP = async (user, dinner_group) => {
  const response = await httpService.post(`/member/membership/request/`, {
    user,
    dinner_group,
  });
  return response;
};

export const removeMemberFromDP = (user, dinner_group) =>
  httpService.post(`/member/membership/status-update/`, {
    user,
    dinner_group,
    active: false,
  });

export const setLeaderFromMemberDP = async (user_id, dinner_group_id) => {
  const response = await httpService.post(`/member/set-unset-leader/set/`, {
    user_id,
    dinner_group_id,
  });
  return response;
};

export const setLeaderDP = async (user, dinner_group) => {
  const response = await httpService.post(`/member/membership/request/`, {
    user,
    dinner_group,
    role: 2,
  });
  return response;
};

export const removeLeaderDP = async (user_id, dinner_group_id) => {
  const response = await httpService.post(`/member/set-unset-leader/unset/`, {
    user_id,
    dinner_group_id,
  });
  return response;
};

export const createDinnerParty = async (data) => {
  const response = await httpService.post('/dinner/create/', data);
  return response;
};

export const updateDinnerParty = async (data, dp_id) => {
  const response = await httpService.patch(`/dinner/${dp_id}/update/`, data);
  return response;
};

export const removeDinnerParties = async (id) => {
  const response = await httpService.remove(`/dinner/${id}/delete/`);
  return response;
};

export const toggleArchiveGroup = (id, data) => {
  const response = httpService.patch(`/dinner/${id}/archive/`, data);
  return response;
};

export const acceptRequestToJoinDP = async (userId) => {
  const response = await httpService.patch(
    `/member/membership/${userId}/status-update/`,
    {
      request_status: 3,
      active: true,
    }
  );
  return response;
};

export const declineRequestToJoinDP = async (userId) => {
  const response = await httpService.patch(
    `/member/membership/${userId}/status-update/`,
    {
      request_status: 2,
      active: false,
    }
  );
  return response;
};

export const acceptRequestToCreateGroup = async (requestId) => {
  const response = await httpService.patch(`/dinner/${requestId}/update/`, {
    status: 1,
  });
  return response;
};

export const declineRequestToCreateGroup = async (requestId) => {
  const response = await httpService.patch(`/dinner/${requestId}/update/`, {
    status: 2,
  });
  return response;
};

export const removeMember = async (userId) => {
  const response = await httpService.patch(
    `/member/membership/${userId}/status-update/`,
    {
      request_status: 5,
      active: false,
    }
  );
  return response;
};
export const updatePhotoDP = async (dinnerId, preview) => {
  const response = await httpService.patch(
    `/dinner/${dinnerId}/update/`,
    preview
  );
  return response.data;
};

export const getRSVPDinnerParty = async (DPData) => {
  const response = await httpService.get(
    `/dinner/rsvp/${DPData.dpID}/?date=${DPData.dpDate}`
  );
  return response;
};

export const getRSVPDPStats = (DPData) =>
  httpService.get(`/dinner/rsvp/${DPData.dpID}/stats/?date=${DPData.dpDate}`);

export const searchGroups = (params) =>
  httpService.get(
    `/dinner/find_party/?zip=${params.zip}&title=${params.title}&type=${params.type}`
  );

export const leaveGroup = (membershipID) =>
  httpService.patch(`/member/membership/${membershipID}/status-update/`, {
    request_status: 4,
    active: false,
  });
