import httpService from './http.service';

export const membersList = async ( church, role) => {
  const response = await httpService.get(
    `/church/v1/:churchID/members/${role ? '?role=' + role : ''}`
  );

  return response.data;
};

export const getMemberById = (id) => httpService.get(`/member/v2/users/${id}/`);

export const getMembersList = (church_id, role, search, page, isArchived) =>
  httpService.get(
    `/church/v1/:churchID/members/?role=${role}&search=${search}&cursor=${page}&is_archived=${isArchived}`
  );

export const updateProfileByID = async (
  id,
  {
    email,
    phone,
    address,
    apt_address,
    church_id,
    location,
    church_anniversary,
    country,
    city = null,
    state = null,
    zip_code = null,
  }
) => {
  try {
    if (church_anniversary) {
      const response = await httpService.patch('/member/v1/users/' + id + '/', {
        email,
        phone,
        address,
        apt_address,
        church_id,
        location,
        church_anniversary,
        city,
        state,
        zip_code,
        country,
      });
      return response.data;
    } else {
      const response = await httpService.patch('/member/v1/users/' + id + '/', {
        email,
        phone,
        address,
        location,
        apt_address,
        city,
        state,
        zip_code,
        country,
      });
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const setAdmin = async (user_data, church_id) => {
  const response = await httpService.post('/member/church-staff/create/', {
    ...user_data,
    church: church_id,
  });

  return response;
};

export const removeAdmin = async (user_id, church_id) => {
  const response = await httpService.post('/member/set-unset-admin/unset/', {
    user_id,
    church_id: church_id,
  });

  return response;
};

export const addGuest = async (data) => {
  const response = await httpService.post('/member/v1/guest-user/add/', data);
  return response;
};

export const searchMember = (str) =>
  httpService.get(`/member/search/?search_string=${str}`);

export const getMemberAttendanceHistory = (userID, page = '', churchId) =>
  httpService.get(`/member/users/${userID}/attendance-history/?cursor=${page}&church_id=${churchId}`);

export const archiveMember = (data) =>
  httpService.patch(`member/status-update/`, data);
