import axios from 'axios';
import httpService from './http.service';

export const listOfJoiningRequest = (church_id) =>
  httpService.get(`/member/membership/church/${church_id}/requests/`);

export const listOfGroupsRequest = async (church_id) => {
  const response = await httpService.get(
    `/dinner/public-group/${church_id}/requests/`
  );

  return response.data.data;
};

export const getAllChats = async () => {
  const response = await httpService.get(`/chat/user-chats/`);

  return response.data;
};

export const searchChats = async (searchParam) => {
  const response = await httpService.get(
    `/chat/room/search/?search=${searchParam}`
  );

  return response.data.data;
};

export const createOneToOneChat = (userID) =>
  httpService.post(`/chat/room/one-to-one-chat/create/`, {
    other_user: userID,
  });

export const createGroupChat = async (dinner, chat_id) => {
  const response = await httpService.post(`/chat/dp-group-chats/create/`, {
    dinner,
    chat_id,
  });

  return response.data;
};

export const uploadImageInChat = async (image) => {
  const response = await httpService.post(`/chat/upload-image/`, image);

  return response.data.data;
};

export const sendHomeFeedMessage = async (
  church_id,
  text,
  image,
  added_by,
  video
) => {
  const fd = new FormData();
  image && fd.append('images', image);

  fd.append('church', church_id);
  fd.append('added_by', added_by);
  const response = await httpService.post(
    `/church/feed-messages/create/`,
    video
      ? { video, added_by, church: church_id }
      : image
      ? fd
      : { text, added_by, church: church_id }
  );

  return response;
};

export const sendEveryoneMessage = (churchID) =>
  httpService.post(`/chat/room/broadcast-chat/create/`, {
    church_id: churchID,
  });

export const getAllRooms = (page, is_read = '') =>
  httpService.get(`/chat/room/list/?cursor=${page}&is_read=${is_read}`);

export const getFilteredChats = (data) =>
  httpService.get(
    `/chat/v1/room/list/?paginated=True&type=${data.type}&cursor=${data.page}`
  );

export const getAllRoomsChurch = () =>
  httpService.get(`/chat/room/church/:churchID/list/`);

export const getAllRoomsDp = (idDp) =>
  httpService.get(`/chat/room/dinner/${idDp}/list/`);

export const createOneToOneRoom = (otherUserId) =>
  httpService.post('/chat/room/one-to-one-chat/create/', {
    other_user: otherUserId,
  });

export const createDpRoom = async (data) => {
  const response = await httpService.post(
    '/chat/room/dinner-party-chat/create/',
    data
  );
  return response.data.data;
};

export const createServiceRoom = (idChurch) =>
  httpService.post(`/chat/room/service-chat/create/`, idChurch);

export const getAllMessagesRoom = (data) =>
  httpService.get(
    `/chat/room/${data.idRoom}/messages/?cursor=${data.pageRoom}`
  );

export const createLeadersChat = (idDP) =>
  httpService.post('/chat/room/party-leader-admin-chat/create/', {
    dinner_id: idDP,
  });

export const getReportsAndRequests = (data) =>
  httpService.get(`/chat/v1/inbox-cards/?type=${data.type}`);

export const getTotalAttendanceNote = (idAttendance) =>
  httpService.get(`/dinner/attendance/${idAttendance}/attendance-summary/`);

export const createAdminChat = (church_id) =>
  httpService.post('/chat/room/admin-chat/create/', { church_id: church_id });

export const sendAboutReadCard = (idCard) =>
  httpService.put(`/chat/inbox-cards/${idCard}/update-is-read/`, {
    is_read: true,
  });

export const messageReportCall = (data) =>
  httpService.post('/chat/message/report/', data);

export const messageDelete = (messageID) =>
  httpService.remove(`/chat/message/${messageID}/delete/`);

export const messageReportDismiss = (messageReportID) =>
  httpService.patch(`/chat/dismiss/${messageReportID}/report-card/`);

export const singleChatRoom = (chatRoomID) =>
  httpService.get(`/chat/room/${chatRoomID}/details/`);

export const prayerDetails = (prayerID) =>
  httpService.get(`/chat/prayer/${prayerID}/`);

export const deletePrayer = (prayerID) =>
  httpService.remove(`/chat/prayer/${prayerID}/`);

export const updatePrayer = (prayerID, data) =>
  httpService.patch(`/chat/prayer/${prayerID}/`, data);

export const getPrayerList = (chatRoom, user, type) =>
  httpService.get(
    `/chat/prayer/list/?${chatRoom ? 'chat_room=' + chatRoom : ''}${
      user ? (chatRoom ? '&' : '') + 'added_by=' + user : ''
    }${type ? (chatRoom || user ? '&' : '') + 'type=' + type : ''}`
  );

export const getMessageByKey = (messagekey) =>
  httpService.get(`/chat/message/${messagekey}/details-with-key/`);

export const getPresignedUrls = (fileNames) =>
  httpService.post('/chat/generate-presigned-urls/', {
    file_names: fileNames,
  });

export const uploadImageToPresigned = (url, formData, fileType) =>
  axios.put(url, formData, {
    header: {
      'Content-Type': fileType,
    },
  });

export const getLeaderInboxCards = (page, groupID = '') =>
  httpService.get(
    `/chat/v1/inbox-cards/?is_read=all&paginated=true&type=member_approval,member_left&cursor=${page}${
      groupID ? '&group_id=' + groupID : ''
    }`
  );

export const readInboxCard = (inboxCardID) =>
  httpService.put(`/chat/inbox-cards/${inboxCardID}/update-is-read/`, {
    is_read: true,
  });

export const deleteInboxCard = (inboxCardID) =>
  httpService.put(`/chat/inbox-cards/${inboxCardID}/dismiss/`, {
    dismiss_card: true,
  });
