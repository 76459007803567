import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  updateChurchSettings,
  getChurchSettings,
} from '../../services/church.service.js';

const initialState = {
  church: {},
  errors: {},
  churchInfo: null,
};

export const updateChurchSettingsThunk = createAsyncThunk(
  'church/updateChurchSettings',
  async (dataInfo) => {
    const data = await updateChurchSettings(dataInfo);
    return data;
  }
);

export const getChurchSettingsThunk = createAsyncThunk(
  'church/getChurchSettings',
  async () => {
    const data = await getChurchSettings();
    return data;
  }
);

const ChurchSlice = createSlice({
  name: 'church',
  initialState,
  extraReducers: {
    [updateChurchSettingsThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [updateChurchSettingsThunk.fulfilled]: (state, action) => {
      state.church = action.payload.data;
      state.isLoading = false;
    },
    [updateChurchSettingsThunk.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getChurchSettingsThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getChurchSettingsThunk.fulfilled]: (state, action) => {
      state.church = action.payload.data;
      state.isLoading = false;
    },
    [getChurchSettingsThunk.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
  reducers: {
    churchUpdate(state, { payload }) {
      state.church = payload;
    },
    churchSelect(state, { payload }) {
      state.churchInfo = payload;
    },
    churchRemove(state) {
      state.churchInfo = null;
    },
  },
});

export const { churchSelect, churchRemove, churchUpdate } = ChurchSlice.actions;

export default ChurchSlice.reducer;
