import axios from 'axios';
import httpService from './http.service';

export const createMasterSchedule = (data, token = null) =>
  httpService.post(
    '/dinner/group-schedule/create/',
    data,
    token ? { Authorization: `Bearer ${token}` } : {}
  );

export const getChurchSchedulesByIDToken = (churchId, token) =>
  axios.get(`/dinner/group-schedule/list/?church_id=${churchId}`, {
    headers: token ? { Authorization: `Bearer ${token}` } : {}
  });

export const createScheduleException = (data) =>
  httpService.post('/dinner/group-schedule/create-exceptions/', data);

export const getChurchSchedules = (churchId) =>
  httpService.get(`/dinner/group-schedule/list/?church_id=:churchID`);

export const getChurchSchedulesByID = (churchId) =>
  httpService.get(`/dinner/group-schedule/list/?church_id=${churchId}`);

export const getScheduleDates = (scheduleId, dateData) => {
  let sdate = new Date(dateData.start);
  let edate = new Date(dateData.end);
  sdate.setHours(0, 0, 0, 0);
  edate.setHours(23, 59, 59, 0);
  sdate = sdate.toISOString().replace('.000', '');
  edate = edate.toISOString().replace('.000', '');
  return httpService.get(
    `/dinner/group-schedule/future-meeting-dates/?schedule_id=${scheduleId}&start_date=${sdate}&end_date=${edate}`
  );
};

export const updateSchedule = (scheduleId, data, token = null) =>
  httpService.patch(
    `/dinner/group-schedule/${scheduleId}/update/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : {}
  );
