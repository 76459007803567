import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader(state, { payload }) {
      state.title = payload;
    },
  },
});

export const { setHeader } = headerSlice.actions;

export default headerSlice.reducer;
