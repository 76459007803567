import httpService from './http.service';
import axios from 'axios';

export const userLogin = (data) => httpService.post('/auth/token/', data);

export const userRegister = (regData) =>
  httpService.post('/member/v1/signup/', regData);

export const getUserByToken = async (token) => {
  const response = await httpService.get(
    '/member/v2/user/',
    token ? { Authorization: `Bearer ${token}` } : null
  );

  return response.data.data;
};

export const getUserByCurrentToken = async (token) => {
  const response = await axios.get('/member/v2/user/', {
    baseURL: `${process.env.NEXT_PUBLIC_STAGING_API}`,
    headers: token ? { Authorization: `Bearer ${token}` } : null,
  });

  return response.data.data;
};

export const UpdateProfileById = (data) =>
  httpService.patch(`/member/v1/users/${data.id}/`, data.data);

export const updateProfileInfo = async (data, token) => {
  const response = await httpService.patch(
    `/member/v2/user/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );
  return response;
};

export const getUserByGoogle = async (data) => {
  const response = await httpService.post('/member/google/', data);
  return response.data;
};

export const getUserByApple = async (data) => {
  const response = await httpService.post('/member/apple/', data);
  return response.data;
};

export const sendLetterByResetPassword = async (email) => {
  const encodeEmail = encodeURIComponent(email);
  const response = await httpService.get(
    `/auth/send-reset-password-email/?email=${encodeEmail}`
  );
  return response;
};

export const resetPassword = async (resetPasswordData) => {
  const response = await httpService.post(
    `/auth/reset-forget-password/`,
    resetPasswordData
  );
  return response;
};

export const updatePhoto = async (photo) => {
  const response = await httpService.patch(
    `/member/update-own-profile/`,
    photo
  );
  return response;
};

export const changePassword = async (
  old_password,
  new_password,
  confirm_new_password
) => {
  const response = await httpService.post(`/member/change-password/`, {
    old_password,
    new_password,
    confirm_new_password,
  });
  return response;
};

export const refreshToken = (church) =>
  httpService.post('/auth/token/refresh/', {
    refresh: localStorage.getItem('refresh_token'),
    church_id: church,
  });

export const changePasswordWithoutOld = (id, data, token) =>
  httpService.post(
    `/member/${id}/change-password/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const voteRSVP = (data) =>
  httpService.post(`/member/rsvp/create/`, data);

export const sendEmailWaitList = (email) =>
  httpService.post(`/church/wait-list/create/`, { email: email });

export const getUserDetailsByID = (userId) =>
  httpService.get(`/member/v2/users/${userId}/`);

export const searchFamily = (search) =>
  httpService.get(`/member/family-member/search/?search_string=${search}`);

export const addFamily = (data) =>
  httpService.post('/member/family-connection/create/', data);

export const addNonUserFamily = (data) =>
  httpService.post('/member/non-user-family/create/', data);

export const removeFamilyConnection = (family_connection_id) =>
  httpService.remove(
    `/member/family-connection/${family_connection_id}/delete/`
  );

export const updateFamilyConnection = (family_connection_id, data) =>
  httpService.patch(
    `/member/family-connection/${family_connection_id}/update/`,
    data
  );

export const checkValidToken = (code) =>
  httpService.post(`/auth/validate-password-reset-key/`, { key: code });

export const checkMemberCreds = (email, token) =>
  httpService.get(
    `member/check-user-exist/?email=${email}`,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const getUserSetupGuide = (churchMembershipID) =>
  httpService.get(`/member/user-setup-guide/${churchMembershipID}/`);

export const updateUserSetupGuide = (churchMembershipID, data) =>
  httpService.patch(`/member/user-setup-guide/${churchMembershipID}/`, data);

export const migrateUserPermissions = (data) =>
  httpService.post(
    '/member/migrate-permissions/',
    data
  );

export const getlatestFeatureAnnouncement = (type) =>
  httpService.get(
    '/member/feature-announcement/latest/',
    {},
    {
      params: {
        type,
      },
    }
  );

export const updateFeatureAnnouncement = (data) =>
  httpService.patch('/member/feature-announcement/seen/update/', data);

export const updateUserChurchSettings = (data) =>
  httpService.patch('/member/user-settings/', data);

export const resendInvitation = async (token) =>
  axios.get(`/member/member-import/resend-invite/?token=${token}`, {
    baseURL: `${process.env.NEXT_PUBLIC_STAGING_API}`,
  });
