import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchValue: '',
};

const searchInputSlice = createSlice({
  name: 'searchInput',
  initialState,
  reducers: {
    setSearchValue(state, { payload }) {
      state.searchValue = payload;
    },
  },
});

export const { setSearchValue } = searchInputSlice.actions;

export default searchInputSlice.reducer;
  