export const alertMessages = {
  NOT_MEET_REQUIREMENTS:
    "The password doesn't meet the requirements on the left",
  CONFIRM_PASSWORD: 'Confirm password',
  PASSWORD_MISMATCH: 'Password mismatch',
  PASSWORD_WAS_CHANGED: 'Password was successfully changed',
  PASSWORD_WASNT_CHANGED: 'Password has not been changed',
};

export const settingsTab = {
  PROFILE: 'Your Profile',
  SECURITY: 'Security',
};

export const familyMemberStatus = {
  PENDING: 1,
  DECLINED: 2,
  ACCEPTED: 3,
  LEFT: 4,
  REMOVED: 5,
};

export const familyRelations = {
  HUSBAND: 1,
  WIFE: 2,
  DAUGHTER: 3,
  SON: 4,
};

export const familyMemberRelationShipOptions = [
  {
    value: 1,
    text: 'Husband',
  },
  {
    value: 2,
    text: 'Wife',
  },
  {
    value: 3,
    text: 'Daughter',
  },
  {
    value: 4,
    text: 'Son',
  },
];
